import rfdc from 'rfdc';
import loadImage from 'blueimp-load-image';
import analyzeImage from '../services/analyzeImage';
import { getImageDimensions, findMasks, getPropertyInPath, anonymizeImage } from '../utils/generalFunctions';
import { resizeFaceArea, resizeResults } from '../utils/resize';
import { rgbToHex, hexToRgb } from '../utils/color';

import { findings, skintoneReferences, makeupEnum, getOutputType, hairEnum } from '../utils/mapCV';

const cvFindingsMap = Object.entries(findings).reduce((acc, [key, value]) => ({ ...acc, [value.cv]: key }), {});

const childrenMap = Object.keys(findings).reduce((acc, key) => {
  const value = findings[key];
  if (!value.parent) return acc;

  return { ...acc, [findings[key].cv]: key };
}, {});
const childrenComponents = Object.keys(childrenMap);

const getSubfindings = (components = []) => components.filter((component) => childrenComponents.includes(component));

/**
 * Applies a scaling factor to the value with a capped adjustment.
 * The adjustment is scaled by `factor` but capped at a maximum of 1.
 *
 * @param value - The base value to adjust.
 * @param factor - The scaling factor for adjustment.
 * @returns The adjusted value.
 */
const applyFactor = (value, factor) => (value + Math.min(value * factor, 1)) / 2;

const normalize = (value, fromMin, fromMax, factor) => {
  if (value === null || value === undefined) return value;
  const adjustedValue = factor ? applyFactor(value, factor) : value;
  const toMin = 0;
  const toMax = 100;
  const percent = (adjustedValue - fromMin) / (fromMax - fromMin);
  return Math.round(percent * (toMax - toMin) + toMin);
};

const isBrowserCorrectingOrientation = () =>
  // Detect image-orientation: from-image. Active on safari 15.1 and chrome v
  // https://github.com/blueimp/JavaScript-Load-Image/issues/97#issuecomment-609979184
  getComputedStyle(document.body).imageOrientation === 'from-image';
/** Class representing a RevieveCV image manipulator. */
class RevieveCV {
  /**
   * RevieveCV class.
   * Revieve Computer Vision module
   * @constructor
   * @param {Object} sdk - instance of RevieveSDK
   */
  constructor(sdk) {
    if (!sdk) {
      console.error('RevieveCV: Constructor needs an SDK instance');
      Object.create(null);
    }
    this._sdk = sdk;
    this._state = {};
  }

  /**
   * Method to set sdk state findings entries with cv values.
   */
  setFindings() {
    this._sdk.setCVFloatValue(findings.hyperpigmentation.cv, this.getHyperpigmentation());
    this._sdk.setCVFloatValue(findings.melasma.cv, this.getMelasma());
    this._sdk.setCVFloatValue(findings.freckles.cv, this.getFreckles());
    this._sdk.setCVFloatValue(findings.darkSpots.cv, this.getDarkSpotsAdjusted() ?? this.getDarkSpots());
    this._sdk.setCVFloatValue(findings.wrinkles.cv, this.getWrinkles());
    this._sdk.setCVFloatValue(findings.redness.cv, this.getRednessAbsolute() ?? this.getRedness());
    this._sdk.setCVFloatValue(findings.texture.cv, this.getTexture());
    this._sdk.setCVFloatValue(findings.acne.cv, this.getAcne());
    this._sdk.setCVFloatValue(findings.smoothness.cv, this.getSmoothness());
    this._sdk.setCVFloatValue(findings.skinShine.cv, this.getSkinShine());
    this._sdk.setCVFloatValue(findings.radiance.cv, this.getRadianceAdjusted() ?? this.getRadiance());
    this._sdk.setCVFloatValue(findings.dullSkin.cv, this.getDullSkin());
    this._sdk.setCVFloatValue(findings.unevenSkinTone.cv, this.getUnevenSkinTone());
    this._sdk.setCVFloatValue(findings.eyebags.cv, this.getEyebags());
    this._sdk.setCVFloatValue(findings.darkcircles.cv, this.getDarkcircles());
    this._sdk.setCVFloatValue(findings.poreDilation.cv, this.getPoreDilation());
    this._sdk.setCVFloatValue(findings.skinSagging.cv, this.getSkinSagging());
    this._sdk.setCVFloatValue(findings.skinFirmness.cv, this.getSkinFirmness());
    this._sdk.setCVFloatValue(findings.hairVolume.cv, this.getHairVolume());
    this._sdk.setCVFloatValue(findings.skinAge.cv, this.getSkinAge(), 0, 150);
    // recommender expects index number for face_shape and undertone
    this._sdk.setCVEnumValue(
      findings.faceShape.cv,
      this.getElementFromResults(findings.faceShape.path),
      makeupEnum.faceShape,
    );
    this._sdk.setCVEnumValue(
      findings.skinUndertone.cv,
      this.getElementFromResults(findings.skinUndertone.path),
      makeupEnum.skinUndertone,
    );

    let eyeColor = this.getEyeColor();
    if (eyeColor) {
      this._sdk.setCVEnumValue(findings.eyeColor.cv, eyeColor.toLowerCase(), makeupEnum.eyeColor);
    }

    let skinFoundationData = this.getSkinFoundation();
    if (skinFoundationData) {
      this._sdk.setSkintoneColor(skinFoundationData.hex);
      this._sdk.setSkintone(skinFoundationData.skintone, false, null, false);
    }

    let hairTypeData = this.getHairType();
    if (hairTypeData) {
      this._sdk.setCVEnumValue(
        findings.hairType.cv,
        this.getElementFromResults(findings.hairType.path),
        hairEnum.hairType,
      );
      // as a bonus, an extra property eg. wavy_hair_cv = 1
      const metricName = hairTypeData + '_hair';
      this._sdk.setCVEnumValue(metricName, 1, [1]);
    }

    let hairFrizzinessData = this.getHairFrizziness();
    if (hairFrizzinessData) {
      this._sdk.setCVEnumValue(
        findings.hairFrizziness.cv,
        this.getElementFromResults(findings.hairFrizziness.path),
        hairEnum.hairFrizziness,
      );
    }

    const subfindings = getSubfindings(this._sdk.getConfiguration().components);
    for (const name of subfindings) {
      const childrenName = childrenMap[name];
      const children = findings[childrenName];

      const value = this.getSubfindingFromResults(children.parent.name, children.parent.measurements)?.value;
      this._sdk.setCVFloatValue(children.cv, value);
    }
  }

  /**
   * Sets an image
   * @returns {Promise} returns image dimensions
   */
  setImage(image) {
    return new Promise((resolve) => {
      if (typeof image !== 'string') {
        loadImage(
          image,
          (canvas) => {
            let base64data = canvas.toDataURL('image/jpeg');
            this._state.image = base64data;
            this._sdk.analytics.sendEvent('RevieveCV.setImage');
            getImageDimensions(base64data).then((dimensions) => {
              this._state.imageDimensions = dimensions;
              resolve(dimensions);
            });
          },
          {
            maxWidth: 3000,
            maxHeight: 3000,
            canvas: true,
            orientation: !isBrowserCorrectingOrientation(),
            meta: false,
          },
        );
      } else {
        this._state.image = image;
        getImageDimensions(image).then((dimensions) => {
          this._state.imageDimensions = dimensions;
          resolve(dimensions);
        });
      }
      this._sdk.AR._state.needsReset = true;
    });
  }

  /**
   * Method to analyze an image and get results from server.
   * @returns {Promise}
   */
  analyzeImage() {
    return new Promise((resolve, reject) => {
      if (!this._state.image) {
        resolve();
      }
      this._sdk.analytics.sendEvent('RevieveCV.analyzeImage');

      let skintone = this._sdk.getConfiguration().skintone;
      let hairtype = this._sdk.getConfiguration().hairtype;
      let i = 0;
      while (skintone === undefined && i < (this._sdk.getConfiguration().variations || []).length) {
        skintone = this._sdk.getConfiguration().variations[i].skintone;
        i++;
      }
      let j = 0;
      while (hairtype === undefined && j < (this._sdk.getConfiguration().variations || []).length) {
        hairtype = this._sdk.getConfiguration().variations[j].hairtype;
        j++;
      }
      const components = this._sdk.getConfiguration().components || [];
      const cvComponents = components.filter((component) => !childrenComponents.includes(component));
      analyzeImage(
        this._sdk.getApiVersion(),
        this._sdk.getPartnerId(),
        this._state.image,
        skintone,
        this._sdk.getConfiguration().skintone_color_ui,
        this._sdk.getConfiguration().gender_ui,
        hairtype,
        this._sdk.isTesting(),
        this._sdk.getEnvironment(),
        cvComponents,
        this._state.timeout,
      )
        .then((response) => {
          if (!response || (response && response.message === 'Error')) {
            this._state.error = true;
            let message = response.status[0].description;
            console.error(message);
            if (response && response.status) {
              this._state.status = response.status;
            }
            if (response && response.message) {
              this._state.message = response.message;
            }
            reject(new Error(message));
          } else {
            if (response.status) {
              this._state.status = response.status;
              for (const status of this._state.status) {
                if (status.isError) {
                  this._state.error = true;
                }
              }
            }
            this._state.message = response.message;
            if (this._state.message === 'Error') {
              this._sdk.analytics.sendEvent('RevieveCV.analyzeImageError');
            } else if (this._state.message === 'OK') {
              this._sdk.analytics.sendEvent('RevieveCV.analyzeImageSuccess');
            } else if (this._state.message === 'Warning') {
              for (const status of this._state.status) {
                this._sdk.analytics.sendEvent('RevieveCV.analyzeImageWarning', status.description);
              }
            }
            this._state.results = response.results;
            this._state.faceArea = response.face_area;
            this._state.masks = findMasks(this._state.results);
            this._state.imageConditions = response.image_conditions;
            this._state.error = false;
            this._sdk.AR._state.whiteBalanceValue = response.inverse_color_balance_rb;
            this.setFindings();

            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @typedef Size
   * @property {number} width Width of the element.
   * @property {number} height Height of the element.
   */

  /**
   * Use this method to resize image and result visualizations after analysis
   * @returns {Promise<Size|Error>} A promise that contains the size of the results image when fulfilled.
   */
  resizeResults({ maxWidth, maxHeight }) {
    return new Promise((resolve, reject) => {
      if (!this._state.image || !this._state.imageDimensions || !this._state.results || this._state.error) {
        return reject(new Error('You need to successfully analyze the image before'));
      }
      const { width: originalWidth, height: originalHeight } = this._state.imageDimensions;

      if (originalWidth <= maxWidth && originalHeight <= maxHeight) {
        return resolve({ width: originalWidth, heigh: originalHeight });
      }

      return loadImage(
        this._state.image,
        (canvas) => {
          const { width, height } = canvas;
          const resizeFactor = { xFactor: width / originalWidth, yFactor: height / originalHeight };
          const base64data = canvas.toDataURL('image/jpeg');

          this._state.image = base64data;
          this._state.imageDimensions = { width, height };
          resizeFaceArea(this._state.faceArea, resizeFactor);
          resizeResults(this._state.results, resizeFactor);
          this._state.masks = findMasks(this._state.results);
          resolve({ width, height });
        },
        {
          maxWidth,
          maxHeight,
          canvas: true,
          orientation: !isBrowserCorrectingOrientation(),
          meta: false,
        },
      );
    });
  }

  /**
   * Sets the timeout for image analysis service call. Default is 60000 ms.
   *
   * @param {number} timeout Timeout in milliseconds
   */
  setTimeout(timeout) {
    this._state.timeout = timeout;
  }

  /**
   * Use this method to get the state of CV module
   * @returns {Object} General state
   */
  getState() {
    return this._state;
  }

  /**
   * Returns a copy of the state configuration object in SDK
   *
   * @returns {Object} JSON with all configuration values
   */
  getSeralizableState() {
    return rfdc()(this._state);
  }

  /**
   * Hydrate the state with the provided one
   *
   * @param {Object} state JSON with all configuration values
   */
  hydrateState(state) {
    if (state) {
      this._state = rfdc()(state);
    }
  }

  /**
   * Use this method to check if there were errors in image analysis.
   * @returns {Boolean} Error
   */
  hasError() {
    return this._state.error;
  }

  /**
   * Use this method to check warnings and errors in image analysis.
   * @returns {Object[]} JSON object with description, idx, and isError boolean.
   */
  getStatus() {
    return this._state.status;
  }

  /**
   * Get the results object from server image analysis
   * @returns {Object} results
   */
  getResults() {
    return this._state.results;
  }

  /**
   * Get the object with the information of the face area from server image analysis
   * @returns {Object} results
   */
  getFaceArea() {
    return this._state.faceArea;
  }

  /**
   * Get the masks detected by server image analysis
   * @returns {Object[]} masks
   */
  getMasks() {
    return this._state.masks;
  }

  /**
   * Get the image conditions object from server image analysis (if available)
   * @returns {(Object|undefined)} image conditions
   */
  getImageConditions() {
    return this._state.imageConditions;
  }

  /**
   * Get the image analyzed
   * @returns {Object} image
   */
  getImage() {
    return this._state.image;
  }

  /**
   * Get the image analyzed anonymized
   * @returns {Object} image
   */
  getAnonymizeImage() {
    // we need to check if eyes component is include in CV module
    if (!this._sdk.getConfiguration().components || !this._sdk.getConfiguration().components.includes('eyes')) {
      console.error('You need eyes component active in CV module to apply this effect');
      return null;
    }
    // we have needed to analyze the image before anomymize it
    if (!this._state.results) {
      console.error('You need analyze the image before');
      return null;
    }
    return anonymizeImage({
      image: this._state.image,
      dimensions: this._state.imageDimensions,
      results: this._state.results,
    });
  }

  getFindingInformation(cvName) {
    if (!this._state.results) {
      return null;
    }

    const result = this._state.results.find((element) => element.description === cvName);

    if (result) {
      return result;
    }

    // extra lookup in case it's a submetric, not present on first level of results
    const name = cvFindingsMap[cvName];
    const finding = findings[name];

    if (finding?.parent) {
      return {
        ...this.getSubfindingFromResults(finding.parent.name, finding.parent.measurements),
        description: cvName,
        visualization_data: null,
        sum_measures: [],
        mask_shapes: null,
        message: 'Ok',
        local_warnings: [],
      };
    }

    return null;
  }

  getFindingValueFromResults(name) {
    const map = findings[name];

    if (map.parent) {
      const subfinding = this.getSubfindingFromResults(map.parent.name, map.parent.measurements);
      return subfinding?.value;
    }

    return this.getElementFromResults(map.path);
  }

  getLocalWarnings(name) {
    const warnings = this.getFindingLocalWarningsFromResults(name);
    if (!warnings || !Array.isArray(warnings)) {
      return 0;
    }

    return warnings.length;
  }

  getFindingLocalWarningsFromResults(name) {
    const map = findings[name];

    if (!map.hasWarnings) {
      return null;
    }

    return this.getLocalWarningsFromResults(map.path);
  }

  getElementFromResults(path) {
    if (!this._state.results) {
      return null;
    }
    return getPropertyInPath(this._state.results, path, 'value');
  }

  getLocalWarningsFromResults(path) {
    if (!this._state.results) {
      return null;
    }

    return getPropertyInPath(this._state.results, path, 'local_warnings');
  }

  getSubfindingFromResults(parent, components) {
    if (!this._state.results || !Array.isArray(this._state.results)) {
      return null;
    }

    const parentMetric = this._state.results.find((metric) => metric.description === parent);
    if (
      // eslint-disable-next-line camelcase
      !parentMetric?.measurement_locations ||
      !Array.isArray(parentMetric.measurement_locations) ||
      parentMetric.measurement_locations.length === 0
    ) {
      return null;
    }

    const result = parentMetric.measurement_locations.reduce(
      (acc, part) => {
        if (!components.includes(part?.description)) {
          return acc;
        }

        return {
          sum: acc.sum + part.value,
          count: acc.count + 1,
          measurement_locations: [...acc.measurement_locations, part],
        };
      },
      {
        count: 0,
        sum: 0,
        measurement_locations: [],
      },
    );
    const value = result.count > 0 ? result.sum / result.count : 0;

    return { value, measurement_locations: result.measurement_locations };
  }

  getMasksFromResults(section, measurements) {
    if (!this._state.results) {
      return null;
    }
    const masks = this._state.masks.filter((mask) => mask.section === section).map((mask) => mask.name);
    if (measurements) {
      return masks.filter((name) => measurements.includes(name));
    }
    return masks;
  }

  /**
   * Get the wrinkles value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} wrinkles intensity
   */
  getWrinkles(normalized = false) {
    const value = this.getElementFromResults(findings.wrinkles.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the redness value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} redness intensity
   */
  getRedness(normalized = false) {
    const value = this.getElementFromResults(findings.redness.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the redness absolute value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} redness intensity
   */
  getRednessAbsolute(normalized = false) {
    const value = this.getElementFromResults(findings.rednessAbsolute.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the hyperpigmentation value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} hyperpigmentation intensity
   */
  getHyperpigmentation(normalized = false) {
    const value = this.getElementFromResults(findings.hyperpigmentation.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the melasma value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} melasma intensity
   */
  getMelasma(normalized = false) {
    const value = this.getElementFromResults(findings.melasma.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the freckles value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} freckles intensity
   */
  getFreckles(normalized = false) {
    const value = this.getElementFromResults(findings.freckles.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the dark spots value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} dark spots intensity
   */
  getDarkSpots(normalized = false) {
    const value = this.getElementFromResults(findings.darkSpots.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the dark spots adjusted value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} dark spots intensity
   */
  getDarkSpotsAdjusted(normalized = false) {
    const value = this.getElementFromResults(findings.darkSpotsAdjusted.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the dull skin value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} dull skin intensity
   */
  getDullSkin(normalized = false) {
    const value = this.getElementFromResults(findings.dullSkin.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the radiance value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} radiance intensity
   */
  getRadiance(normalized = false) {
    const value = this.getElementFromResults(findings.radiance.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the radiance adjusted value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} radiance intensity
   */
  getRadianceAdjusted(normalized = false) {
    const value = this.getElementFromResults(findings.radianceAdjusted.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the uneven skin tone value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} uneven skin tone intensity
   */
  getUnevenSkinTone(normalized = false) {
    const value = this.getElementFromResults(findings.unevenSkinTone.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the smoothness value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} smoothness intensity
   */
  getSmoothness(normalized = false) {
    const value = this.getElementFromResults(findings.smoothness.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the eyebags value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} eyebags intensity
   */
  getEyebags(normalized = false) {
    const value = this.getElementFromResults(findings.eyebags.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the dark circles value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} dark circles intensity
   */
  getDarkcircles(normalized = false) {
    const value = this.getElementFromResults(findings.darkcircles.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the pore dilation value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} redness intensity
   */
  getPoreDilation(normalized = false) {
    const value = this.getElementFromResults(findings.poreDilation.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the skin sagging value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} redness intensity
   */
  getSkinSagging(normalized = false) {
    const value = this.getElementFromResults(findings.skinSagging.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get the skin firmness value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} redness intensity
   */
  getSkinFirmness(normalized = false) {
    const value = this.getElementFromResults(findings.skinFirmness.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get texture value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} texture intensity
   */
  getTexture(normalized = false) {
    const value = this.getElementFromResults(findings.texture.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get skin shine value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} skin shine overall value
   */
  getSkinShine(normalized = false) {
    const value = this.getElementFromResults(findings.skinShine.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get acne value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} acne overall value
   */
  getAcne(normalized = false) {
    const value = this.getElementFromResults(findings.acne.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get hair volume value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} acne overall value
   */
  getHairVolume(normalized = false) {
    const value = this.getElementFromResults(findings.hairVolume.path);
    return normalized ? normalize(value, 0, 1) : value;
  }

  /**
   * Get hair frizz value detected by CV processor
   * @returns {string} hair frizz value
   */
  getHairFrizziness() {
    const value = this.getElementFromResults(findings.hairFrizziness.path);
    return findings.hairFrizziness.enum[value];
  }

  /**
   * Get the face shape value detected by CV processor
   * @returns {string} face shape detected
   */
  getHairType() {
    const value = this.getElementFromResults(findings.hairType.path);
    return findings.hairType.enum[value];
  }

  /**
   * Get the face shape value detected by CV processor
   * @returns {float} face shape detected
   */
  getFaceShape() {
    const value = this.getElementFromResults(findings.faceShape.path);
    return findings.faceShape.enum[value];
  }

  /**
   * Get the eye color value detected by CV processor
   * @returns {float} eye color detected
   */
  getEyeColor() {
    const value = this.getElementFromResults(findings.eyeColor.path);
    return findings.eyeColor.enum[value];
  }

  /**
   * Get the skin undertone value detected by CV processor
   * @returns {float} skin undertone detected
   */
  getSkinUndertone() {
    const value = this.getElementFromResults(findings.skinUndertone.path);
    return findings.skinUndertone.enum[value];
  }

  /**
   * Get the skin foundation info detected by CV processor
   * @returns {Object} returns an object with rgb and hex skintone color
   * and skintone value in Fitzpatrick scale detected
   */
  getSkinFoundation() {
    const value = this.getElementFromResults(findings.skinFoundation.path);
    let skinFoundationData = null;
    if (value && value.length > 0) {
      let rgbValue = value[0];
      let skintoneDetected = this.getElementFromResults(findings.skinFitzpatrick.path);
      skinFoundationData = {
        rgb: rgbValue,
        hex: rgbToHex(rgbValue),
        skintone: parseInt(skintoneDetected, 10),
      };
    }
    return skinFoundationData;
  }

  /**
   * Get nasolabial folds value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} nasolabial folds overall value
   */
  getNasolabialFolds(normalized = false) {
    return this.getFinding('nasolabialFolds', 'float', normalized);
  }

  /**
   * Get marionette lines value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} marionette lines overall value
   */
  getMarionetteLines(normalized = false) {
    return this.getFinding('marionetteLines', 'float', normalized);
  }

  /**
   * Get under eye lines value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} under eye lines overall value
   */
  getUnderEyeLines(normalized = false) {
    return this.getFinding('underEyeLines', 'float', normalized);
  }

  /**
   * Get forehead lines value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} forehead lines overall value
   */
  getForeheadLines(normalized = false) {
    return this.getFinding('foreheadLines', 'float', normalized);
  }

  /**
   * Get forehead lines value detected by CV processor
   * @param {bool} normalized - force the returned value to be between 0 and 1
   * @returns {float} forehead lines overall value
   */
  getWrinklesForeheadEyes(normalized = false) {
    return this.getFinding('wrinklesForeheadEyes', 'float', normalized);
  }

  /**
   * Get skin age value detected by CV processor
   * @returns {number} skin age value
   */
  getSkinAge() {
    return this.getFinding('skinAge', 'integer');
  }

  /**
   * Method to detect skin tone in Fitzpatrick scale from skin color
   * @param {String|Array} skinColor - base skin color for skintone detection in hex or rgb format
   * @returns {String} string with Fitzpatrick scale's value detected
   */
  detectSkintoneFromSkinColor(skinColor) {
    let rgbValue = skinColor;
    if (typeof skinColor === 'string') {
      // we need to convert it to rgb value
      rgbValue = hexToRgb(skinColor);
    }
    // we need to detect the skintone value
    let skintoneDetected = null;
    let lastDistance = null;

    for (const skintoneCode in skintoneReferences) {
      if (skintoneReferences[skintoneCode]) {
        const greenReference = skintoneReferences[skintoneCode];
        let distanceToReference = rgbValue[1] - greenReference;
        if (distanceToReference < 0) {
          distanceToReference *= -1;
        }
        if (!lastDistance || distanceToReference < lastDistance) {
          lastDistance = distanceToReference;
          skintoneDetected = skintoneCode;
        }
      }
    }
    return skintoneDetected;
  }

  getFinding(name, type, normalized = false, useFactor = false) {
    const value = this.getFindingValueFromResults(name);

    if (type === 'float') {
      const factor = useFactor ? findings[name]?.normalizationFactor : undefined;
      return normalized ? normalize(value, 0, 1, factor) : value;
    }

    if (type === 'enum') {
      return findings[name].enum[value];
    }

    return value;
  }

  /** Get JSON structure with all the data detected by CV motor
   * @returns {Object} JSON structure with data
   */
  getFindings(normalized = false, useFactor = false) {
    if (!this._state.results) {
      return null;
    }

    const subfindings = getSubfindings(this._sdk.getConfiguration().components);

    return Object.keys(findings)
      .filter((name) => findings[name].cv)
      .reduce((acc, name) => {
        const finding = findings[name];
        // skip submetric if not enabled in components
        if (finding.parent && !subfindings.includes(finding.cv)) {
          return acc;
        }
        const warnings = this.getLocalWarnings(name);
        // eslint-disable-next-line prefer-object-spread
        const value = Object.assign(
          {
            value: this.getFinding(name, finding.type, normalized, useFactor),
            type: getOutputType(finding.type, normalized),
            cv: finding.cv + '_cv',
          },
          finding.mask ? { masks: this.getMasksFromResults(finding.mask, finding.parent?.measurements) } : null,
          finding.positive ? { positive: true } : null,
          warnings > 0 ? { hasWarnings: true } : null,
        );
        Object.assign(acc, { [name]: value });
        return acc;
      }, {});
  }

  getFindingsFilterMap(type) {
    // TODO: handle submetrics
    return Object.keys(findings)
      .filter((name) => {
        if (!findings[name].cv) return false;
        return type === undefined || type === findings[name].type;
      })
      .reduce((acc, name) => {
        Object.assign(acc, { [name]: findings[name].cv + '_cv' });
        return acc;
      }, {});
  }
}

export default RevieveCV;
