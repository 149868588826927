import ga from 'universal-ga';

class Analytics {
  /**
   * Analytics class.
   * Revieve Analytics module
   * @constructor
   */
  constructor() {
    this._trackers = [];
    this.gaInitialized = false;
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  enable() {
    this.enabled = true;
  }

  /**
   * Method to get active trackers
   * @returns {Array} array with the active trackers registered for this instance
   */
  getTrackers() {
    return this._trackers;
  }

  /**
   * Method to add trackers to analytics module.
   * @returns {Boolean} true if trackers are added successfully
   * @param {Array} trackersInfo - Array with JSON objects with trackers configuration.
   * @param {Boolean} avoidCookies- flag for disable cookies for analytics.
   * Each tracker must specify provider, prefix and account.
   */
  addTrackers(trackersInfo, avoidCookies) {
    trackersInfo.map((trackerInfo, index) => {
      let trackerName = null;
      if (trackerInfo.provider === 'ga') {
        if (!this.gaInitialized) {
          ga.initialize(trackerInfo.account, avoidCookies && { storage: 'none' });
          this.gaInitialized = true;
        } else {
          trackerName = 'tracker' + index;
          ga.create(trackerInfo.account, trackerName);
        }
      }
      this._trackers.push({ info: trackerInfo, name: trackerName });
      return true;
    });
    return true;
  }

  /**
   * Method to add a custom dimension to GA trackers
   * @param {Integer} trackerIndex - Tracker's index where you want to add the custom dimension
   * @param {Integer} dimensionIndex - index of your custom dimention in your GA configuration
   * @param {String} value - value to the custom dimension
   */
  setCustomDimensionToGaTracker(trackerIndex, dimensionIndex, value) {
    return this._setCustomParameter(trackerIndex, 'dimension', dimensionIndex, value);
  }

  /**
   * Method to add a custom metric to GA trackers
   * @param {Integer} trackerIndex - Tracker's index where you want to add the custom metric
   * @param {Integer} metricIndex  index of your custom metric in your GA configuration
   * @param {String} value - value to the custom metric
   */
  setCustomMetricToGaTracker(trackerIndex, metricIndex, value) {
    return this._setCustomParameter(trackerIndex, 'metric', metricIndex, value);
  }

  _setCustomParameter(trackerIndex, type, index, value) {
    if (!this._trackers || !this._trackers[trackerIndex]) {
      return;
    }
    // this mehtod only can be executed for google analytics trackers
    if (this._trackers[trackerIndex].info.provider !== 'ga') {
      return;
    }
    let tracker = this._trackers[trackerIndex];
    if (tracker.name) {
      ga.name(tracker.name).custom(type + index, value);
    } else {
      ga.custom(type + index, value);
    }
  }

  /**
   * Method to send an event to all the trackers registered
   * @param {String} eventId - Id of the event
   * @param {String} value - value to register with event
   */
  sendEvent(eventId, value = null) {
    if (!eventId || !this.enabled) {
      return;
    }
    this._trackers.map((tracker) => {
      let eventInfo = eventMapping[tracker.info.provider][eventId];
      if (!eventInfo) {
        return false;
      }
      switch (tracker.info.provider) {
        case 'ga':
          if (tracker.name) {
            ga.name(tracker.name).event(
              tracker.info.prefix + '_' + eventInfo.category,
              eventInfo.action,
              eventInfo.label ? { eventLabel: eventInfo.label + value } : null,
            );
          } else {
            ga.event(
              tracker.info.prefix + '_' + eventInfo.category,
              eventInfo.action,
              eventInfo.label ? { eventLabel: eventInfo.label + value } : null,
            );
          }
          break;
        default:
          break;
      }
      return true;
    });
  }
}

let eventMapping = {
  ga: {
    'RevieveSDK.setGender': { category: 'SDK', action: 'setConfiguration', label: 'gender_' },
    'RevieveSDK.setCategories': { category: 'SDK', action: 'setConfiguration', label: 'categories' },
    'RevieveSDK.setAge': { category: 'SDK', action: 'setConfiguration', label: 'age_' },
    'RevieveSDK.setLat': { category: 'SDK', action: 'setConfiguration', label: 'lat_' },
    'RevieveSDK.setLon': { category: 'SDK', action: 'setConfiguration', label: 'lon_' },
    'RevieveSDK.setWrinkles': { category: 'SDK', action: 'setConfiguration', label: 'wrinkles_' },
    'RevieveSDK.setEyebags': { category: 'SDK', action: 'setConfiguration', label: 'eyebags_' },
    'RevieveSDK.setDarkSpots': { category: 'SDK', action: 'setConfiguration', label: 'darkspots_' },
    'RevieveSDK.setSkintone': { category: 'SDK', action: 'setConfiguration', label: 'skintone_' },
    'RevieveSDK.setSkintoneColor': { category: 'SDK', action: 'setConfiguration', label: 'skintoneColor_' },
    'RevieveSDK.setCuperose': { category: 'SDK', action: 'setConfiguration', label: 'cuperose_' },
    'RevieveSDK.setRocasea': { category: 'SDK', action: 'setConfiguration', label: 'rocasea_' },
    'RevieveSDK.setAcne': { category: 'SDK', action: 'setConfiguration', label: 'acne_' },
    'RevieveSDK.setEczema': { category: 'SDK', action: 'setConfiguration', label: 'eczema_' },
    'RevieveSDK.setHyperpigmentation': { category: 'SDK', action: 'setConfiguration', label: 'hyperpigmentation_' },
    'RevieveSDK.setOilyness': { category: 'SDK', action: 'setConfiguration', label: 'oilyness_' },
    'RevieveSDK.setDullTired': { category: 'SDK', action: 'setConfiguration', label: 'dulltired_' },
    'RevieveSDK.setPsoriasis': { category: 'SDK', action: 'setConfiguration', label: 'psoriasis_' },
    'RevieveSDK.setOrganic': { category: 'SDK', action: 'setConfiguration', label: 'organic_' },
    'RevieveSDK.setVegan': { category: 'SDK', action: 'setConfiguration', label: 'vegan_' },
    'RevieveSDK.setAntiAge': { category: 'SDK', action: 'setConfiguration', label: 'antiage_' },
    'RevieveSDK.setFragranceFree': { category: 'SDK', action: 'setConfiguration', label: 'fragranceFree_' },
    'RevieveSDK.setParabenFree': { category: 'SDK', action: 'setConfiguration', label: 'parabenFree_' },
    'RevieveSDK.setSunProtection': { category: 'SDK', action: 'setConfiguration', label: 'sunProtection_' },
    'RevieveSDK.setSkinBrightening': { category: 'SDK', action: 'setConfiguration', label: 'skinBrightening_' },
    'RevieveSDK.setSkinFirming': { category: 'SDK', action: 'setConfiguration', label: 'skinFirming_' },
    'RevieveSDK.setCustomAttribute': { category: 'SDK', action: 'setConfiguration', label: 'customAttribute_' },
    'RevieveSDK.setSkintype': { category: 'SDK', action: 'setConfiguration', label: 'skintype_' },
    'RevieveSDK.setEyeColor': { category: 'SDK', action: 'setConfiguration', label: 'eyeColor_' },
    'RevieveSDK.setHairColor': { category: 'SDK', action: 'setConfiguration', label: 'hairColor_' },
    'RevieveSDK.setHairtype': { category: 'SDK', action: 'setConfiguration', label: 'hairtype_' },
    'RevieveAR.reduceEyebags': { category: 'AR', action: 'reduceEyebags', label: 'strength_' },
    'RevieveAR.reduceCrowsFeet': { category: 'AR', action: 'reduceCrowsFeet', label: 'strength_' },
    'RevieveAR.reduceDarkcircles': { category: 'AR', action: 'reduceDarkcircles', label: 'strength_masks_' },
    'RevieveAR.reduceRedness': { category: 'AR', action: 'reduceRedness', label: 'strength_masks_' },
    'RevieveAR.reduceHyperpigmentation': {
      category: 'AR',
      action: 'reduceHyperpigmentation',
      label: 'strenght_masks_',
    },
    'RevieveAR.reduceWrinkles': { category: 'AR', action: 'reduceWrinkles', label: 'strength_masks_' },
    'RevieveAR.brightenSkin': { category: 'AR', action: 'brightenSkin', label: 'strenght_masks_' },

    'RevieveAR.applyLipstick': { category: 'AR', action: 'applyLipstick', label: 'strength_color_' },
    'RevieveAR.applyLipliner': { category: 'AR', action: 'applyLipliner', label: 'strength_color_' },
    'RevieveAR.applyEyeliner': { category: 'AR', action: 'applyEyeliner', label: 'strength_color_' },
    'RevieveAR.applyEyeshadow': { category: 'AR', action: 'applyEyeshadow', label: 'strength_color_' },
    'RevieveAR.applyBlush': { category: 'AR', action: 'applyBlush', label: 'strength_color_' },
    'RevieveAR.applyFoundation': { category: 'AR', action: 'applyFoundation', label: 'strength_color_' },

    'RevieveAR.highlightDarkcircles': { category: 'AR', action: 'highlightDarkcircles', label: 'masks_' },
    'RevieveAR.highlightEyebags': { category: 'AR', action: 'highlightEyebags', label: 'masks_' },
    'RevieveAR.highlightHyperpigmentation': { category: 'AR', action: 'highlightHyperpigmentation', label: 'masks_' },
    'RevieveAR.highlightMelasma': { category: 'AR', action: 'highlightMelasma', label: 'masks_' },
    'RevieveAR.highlightFreckles': { category: 'AR', action: 'highlightFreckles', label: 'masks_' },
    'RevieveAR.highlightDarkSpots': { category: 'AR', action: 'highlightDarkSpots', label: 'masks_' },
    'RevieveAR.highlightUnevenSkinTone': { category: 'AR', action: 'highlightUnevenSkinTone', label: 'masks_' },
    'RevieveAR.highlightAcne': { category: 'AR', action: 'highlightAcne', label: 'masks_' },
    'RevieveAR.highlightRedness': { category: 'AR', action: 'highlightRedness', label: 'masks_' },
    'RevieveAR.highlightTexture': { category: 'AR', action: 'highlightTexture', label: 'masks_' },
    'RevieveAR.highlightWrinkles': { category: 'AR', action: 'highlightWrinkles', label: 'masks_' },
    'RevieveAR.highlightWrinklesIndividually': {
      category: 'AR',
      action: 'highlightWrinklesIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightNasolabialFoldsIndividually': {
      category: 'AR',
      action: 'highlightNasolabialFoldsIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightMarionetteLinesIndividually': {
      category: 'AR',
      action: 'highlightMarionetteLinesIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightUnderEyeLinesIndividually': {
      category: 'AR',
      action: 'highlightUnderEyeLinesIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightForeheadLinesIndividually': {
      category: 'AR',
      action: 'highlightForeheadLinesIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightWrinklesForeheadEyesIndividually': {
      category: 'AR',
      action: 'highlightWrinklesForeheadEyesIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightHyperpigmentationIndividually': {
      category: 'AR',
      action: 'highlightHyperpigmentationIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightFrecklesIndividually': {
      category: 'AR',
      action: 'highlightFrecklesIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightDarkSpotsIndividually': {
      category: 'AR',
      action: 'highlightDarkSpotsIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightAcneIndividually': { category: 'AR', action: 'highlightAcneIndividually', label: 'ranges_' },
    'RevieveAR.highlightSkinShine': { category: 'AR', action: 'highlightSkinShine', label: 'ranges_' },
    'RevieveAR.highlightMelasmaIndividually': {
      category: 'AR',
      action: 'highlightMelasmaIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightUnevenSkinToneIndividually': {
      category: 'AR',
      action: 'highlightUnevenSkinToneIndividually',
      label: 'ranges_',
    },
    'RevieveAR.highlightHyperpigmentationAreaValues': {
      category: 'AR',
      action: 'highlightHyperpigmentationAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightMelasmaAreaValues': { category: 'AR', action: 'highlightMelasmaAreaValues', label: 'ranges_' },
    'RevieveAR.highlightFrecklesAreaValues': {
      category: 'AR',
      action: 'highlightFrecklesAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightDarkSpotsAreaValues': {
      category: 'AR',
      action: 'highlightDarkSpotsAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightWrinklesAreaValues': {
      category: 'AR',
      action: 'highlightWrinklesAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightRednessAreaValues': { category: 'AR', action: 'highlightRednessAreaValues', label: 'ranges_' },
    'RevieveAR.highlightTextureAreaValues': { category: 'AR', action: 'highlightTextureAreaValues', label: 'ranges_' },
    'RevieveAR.highlightAcneAreaValues': { category: 'AR', action: 'highlightAcneAreaValues', label: 'ranges_' },
    'RevieveAR.highlightSmoothnessAreaValues': {
      category: 'AR',
      action: 'highlightSmoothnessAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightUnevenSkinToneAreaValues': {
      category: 'AR',
      action: 'highlightUnevenSkinToneAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightEyebagsAreaValues': { category: 'AR', action: 'highlightEyebagsAreaValues', label: 'ranges_' },
    'RevieveAR.highlightDarkcirclesAreaValues': {
      category: 'AR',
      action: 'highlightDarkcirclesAreaValues',
      label: 'ranges_',
    },
    'RevieveAR.highlightSmoothness': { category: 'AR', action: 'highlightSmoothness', label: 'masks_' },
    'RevieveAR.highlightFaceShape': { category: 'AR', action: 'highlightFaceShape' },
    'RevieveAR.highlightEyeColor': { category: 'AR', action: 'highlightEyeColor' },
    'RevieveAR.highlightEyesShape': { category: 'AR', action: 'highlightEyesShape' },
    'RevieveAR.highlightCheekArea': { category: 'AR', action: 'highlightCheekArea' },
    'RevieveAR.highlightSkinUndertone': { category: 'AR', action: 'highlightSkinUndertone' },
    'RevieveAR.highlightSkinFoundation': { category: 'AR', action: 'highlightSkinFoundation' },
    'RevieveAR.visualizeProduct': { category: 'AR', action: 'visualizeProduct', label: 'productId_' },
    'RevieveCV.setImage': { category: 'CV', action: 'setImage' },
    'RevieveCV.analyzeImage': { category: 'CV', action: 'analyzeImage' },
    'RevieveCV.analyzeImageSuccess': { category: 'CV', action: 'analyzeImageSuccess' },
    'RevieveCV.analyzeImageError': { category: 'CV', action: 'analyzeImageError' },
    'RevieveCV.analyzeImageWarning': { category: 'CV', action: 'analyzeImageWarning', label: '_' },
    'RevievePR.getRecommendedProducts': { category: 'PR', action: 'getRecommendedProducts' },
    'RevievePR.getProduct': { category: 'PR', action: 'getProduct' },
    'RevievePR.getProducts': { category: 'PR', action: 'getProducts' },
    'RevievePR.addToCart': { category: 'PR', action: 'addToCart' },
  },
};

Analytics.providersAvailable = ['ga'];

export default Analytics;
